<template>
  <div class="layout_common">
    <div class="main_box">
      <div class="main_box_con">
        <div class="search_flex">
          <div class="search_left">
            <inputComp
              placeholder="客户名称"
              :hval="customerName"
              @blur="customerNameBlur"
            />
            <inputComp
              placeholder="订单编号"
              :hval="orderCode"
              @blur="orderCodeBlur"
            />
            <inputComp
              placeholder="商品名称"
              :hval="commodityName"
              @blur="commodityNameBlur"
            />
            <selectComp
              placeholder="订单状态"
              :data="orderStatusListToChargeback"
              :hval="orderStatus"
              @change="orderStatusChange"
            />
            <selectComp
              placeholder="租赁时长"
              :data="durationTypeList"
              :hval="durationType"
              @change="durationTypeChange"
            />
            <!-- 战区 -->
            <salesArea
              :hval="salesArea"
              @change="salesAreaChange"
              @saleArea="saleArea"
            />
            <selectComp
              placeholder="退单方式"
              :data="chargebackTypeList"
              :hval="chargebackType"
              @change="chargebackTypeChange"
            />
            <selectComp
              placeholder="有无违约金"
              :data="liquidatedDamagesList"
              :hval="liquidatedDamages"
              @change="liquidatedDamagesChange"
            />
            <selectComp
              placeholder="换产品租"
              :data="chargebackAgainList"
              :hval="chargebackAgain"
              @change="chargebackAgainChange"
            />
            <paramsDictNoForm
              placeholder="退单原因"
              :hval="reason"
              @change="reasonChange"
              :parentCode="101436"
            />
            <dateComp
              :hval="date"
              startPlaceholder="起始退单日期"
              endPlaceholder="截止退单日期"
              @change="dateChange"
            />
            <!-- 商家搜索 -->
            <merchant
              showRankBtn
              :hval="storeName"
              @storeNameChange="storeNameChange"
              @rankClick="isShow = true"
            />
            <btnGroup @search="search" @reset="reset" />
          </div>
        </div>
        <tableComp
          :data="tableData"
          :col-configs="theadName"
          :total="total"
          :currentPage="curPage"
          :pageSize="pageSize"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          v-loading="loading"
        >
          <el-table-column slot="customerName" label="客户名称/订单号">
            <template v-slot="scope">
              <div>{{ scope.row.customerName }}</div>
              <div>{{ scope.row.orderCode }}</div>
              <div></div>
            </template>
          </el-table-column>
          <el-table-column slot="num" label="下单数量/退单数量">
            <template v-slot="scope">
              <div>
                {{ scope.row.leaseQuantity || '-' }} /
                {{ scope.row.chargebackQuantity || '-' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column slot="chargebackDate" label="退单日期/使用时长">
            <template v-slot="scope">
              <div>
                {{ scope.row.chargebackDate || '-' }} /
                {{ scope.row.leaseDurationStr || '-' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column slot="rentStartDate" label="起租日期/期数">
            <template v-slot="scope">
              <div>
                {{ scope.row.rentStartDate || '-' }} /
                {{ scope.row.period || '-' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column slot="operate" label="操作" fixed="right">
            <template v-slot="scope">
              <btnComp btnType="text" @click="edit(scope.row)" v-permission="'修改'">修改</btnComp>
            </template>
          </el-table-column>
        </tableComp>
      </div>
    </div>
    <!-- 商家发货排名 -->
    <rank :isShow="isShow" @close="isShow = false" @selectStore="selectStore" />
    <edit :isShow="editShow" @close="close" :info="info" />
  </div>
</template>
<script>
import merchant from '@/views/components/merchant'
import salesArea from '@/views/components/salesArea'
import rank from '@/views/data-center/components/rank'
import paramsDictNoForm from '@/views/components/paramsDictNoForm'
import edit from './edit'
import {
  durationTypeList,
  chargebackTypeList,
  liquidatedDamagesList,
  chargebackAgainList,
  orderStatusListToChargeback
} from '@/utils/constData'
import { chargebackList } from '@/api'
export default {
  components: {
    merchant,
    salesArea,
    paramsDictNoForm,
    rank,
    edit
  },
  data () {
    return {
      isShow: false,
      editShow: false,
      loading: false,
      curPage: 1,
      pageSize: 10,
      total: 0,
      info: {},
      customerName: '',
      orderCode: '',
      commodityName: '',
      orderStatus: '',
      durationType: '',
      level: '',
      cityId: '',
      salesArea: [],
      chargebackType: '',
      liquidatedDamages: '',
      chargebackAgain: '',
      reason: '',
      startTime: '',
      endTime: '',
      date: [],
      storeName: '',
      storeId: '',
      tableData: [],
      theadName: [
        { slot: 'operate' },
        { prop: 'statusName', label: '处理状态' },
        { prop: 'reasonContent', label: '退单原因' },
        { slot: 'customerName' },
        { prop: 'orderStatusName', label: '订单状态' },
        { prop: 'commodityName', label: '商品名称' },
        { prop: 'storeName', label: '商家' },
        { slot: 'num' },
        { slot: 'rentStartDate' },
        { slot: 'chargebackDate' },
        { prop: 'chargebackTypeName', label: '退单方式' },
        { prop: 'liquidatedDamages', label: '有无违约金' },
        { prop: 'chargebackAgainName', label: '换产品租' }
      ]
    }
  },
  computed: {
    durationTypeList () {
      return durationTypeList
    },
    chargebackTypeList () {
      return chargebackTypeList
    },
    liquidatedDamagesList () {
      return liquidatedDamagesList
    },
    chargebackAgainList () {
      return chargebackAgainList
    },
    orderStatusListToChargeback () {
      return orderStatusListToChargeback
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      const params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        customerName: this.customerName,
        orderCode: this.orderCode,
        commodityName: this.commodityName,
        orderStatus: this.orderStatus,
        durationType: this.durationType,
        chargebackType: this.chargebackType,
        liquidatedDamages: this.liquidatedDamages,
        chargebackAgain: this.chargebackAgain,
        reason: this.reason,
        level: this.level,
        cityId: this.cityId,
        storeId: this.storeId,
        startTime: this.startTime,
        endTime: this.endTime
      }
      this.loading = true
      chargebackList(params).then(res => {
        this.loading = false
        const { list, totalCount } = res.data
        this.tableData = list
        this.total = totalCount
      })
    },
    // 子组件传过来战区信息
    saleArea (salesArea, level, cityId) {
      this.initSalesArea = salesArea
      this.initLevel = level
      this.initCityId = cityId
      this.level = level
      this.cityId = cityId
      this.salesArea = salesArea
      this.$emit('saleArea', salesArea, level, cityId)
    },
    salesAreaChange (salesArea, level, cityId) {
      this.salesArea = salesArea
      this.level = level
      this.cityId = cityId
    },
    customerNameBlur (val) {
      this.customerName = val
    },
    orderCodeBlur (val) {
      this.orderCode = val
    },
    commodityNameBlur (val) {
      this.commodityName = val
    },
    orderStatusChange (val) {
      this.orderStatus = val
    },
    durationTypeChange (val) {
      this.durationType = val
    },
    chargebackTypeChange (val) {
      this.chargebackType = val
    },
    liquidatedDamagesChange (val) {
      this.liquidatedDamages = val
    },
    chargebackAgainChange (val) {
      this.chargebackAgain = val
    },
    reasonChange (val) {
      this.reason = val.code
    },
    storeNameChange (val) {
      this.storeId = val.partnerId
      this.storeName = val.partnerName
    },
    // 选择的商家
    selectStore (val) {
      this.storeId = val.storeId
      this.storeName = val.storeName
    },
    dateChange (startTime, endTime) {
      this.startTime = startTime
      this.endTime = endTime
    },
    // 修改弹窗
    edit (info) {
      this.editShow = true
      this.info = info
    },
    close () {
      this.editShow = false
      this.getList()
    },
    search () {
      this.curPage = 1
      this.getList()
    },
    reset () {
      // 重置后赋值初始值
      this.level = this.initLevel
      this.cityId = this.initCityId
      this.salesArea = this.initSalesArea

      this.customerName = ''
      this.orderCode = ''
      this.commodityName = ''
      this.orderStatus = ''
      this.durationType = ''
      this.chargebackType = ''
      this.liquidatedDamages = ''
      this.chargebackAgain = ''
      this.reason = ''
      this.startTime = ''
      this.endTime = ''
      this.date = []
      this.storeName = ''
      this.storeId = ''
      this.getList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
