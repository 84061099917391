<template>
  <dialogComp
    title="客户退单修改"
    width="740px"
    :isShow="isShow"
    @close="close"
    @sure="sure"
  >
    <baseInfo :info="info" />
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="115px"
    >
      <div class="flex form_item_box">
        <el-form-item label="退单原因:" prop="reason">
          <cascader
            :data="reasonList"
            :hval="
              filterArrNull([
                formData.reason,
                formData.reasonOne,
                formData.reasonTwo,
                formData.reasonThree
              ])
            "
            @change="reasonChange"
            placeholder="请选择退单原因"
          />
        </el-form-item>
        <selectFormComp
          label="再租订单号:"
          prop="againCode"
          :data="againCodeList"
          :hval="formData.againCode"
          placeholder="请选择再租订单号"
          @change="againCodeChange"
        />
      </div>
      <div class="flex form_item_box">
        <radioFormComp
          label="换产品再租:"
          prop="chargebackAgain"
          :radioList="chargebackAgainList"
          :hval="formData.chargebackAgain"
          @change="chargebackAgainChange"
        />
        <radioFormComp
          label="原因确认:"
          prop="status"
          :radioList="statusList"
          :hval="formData.status"
          @change="statusChange"
        />
      </div>
      <inputFormComp
        label="备注说明:"
        prop="remark"
        inputType="textarea"
        :hval="formData.remark"
        @blur="remarkBlur"
        placeholder="请输入备注"
      />
    </el-form>
  </dialogComp>
</template>
<script>
import baseInfo from '../comp/baseInfo'
import { dictThree, orderList, chargebackUpdate } from '@/api'
import { chargebackAgainList } from '@/utils/constData'
import { formatDate } from '@/utils/common'
export default {
  components: {
    baseInfo
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      reasonList: [],
      formData: {
        reason: '',
        reasonOne: '',
        reasonTwo: '',
        reasonThree: '',
        chargebackAgain: 1,
        againCode: '',
        remark: '',
        status: 1
      },
      rules: {
        reason: [
          { required: true, message: '请选择退单原因', trigger: 'change' }
        ],
        chargebackAgain: [
          { required: true, message: '请选择换产品再租', trigger: 'change' }
        ],
        status: [
          { required: true, message: '请选择原因确认', trigger: 'change' }
        ]
      },
      statusList: [
        { label: '待确认', value: 1 },
        { label: '已确认', value: 2 }
      ],
      againCodeList: []
    }
  },
  computed: {
    chargebackAgainList () {
      return chargebackAgainList
    }
  },
  watch: {
    isShow (val) {
      if (val) {
        this.getSelectData()
        this.getLeaseAgainList()
        const {
          reason,
          reasonOne,
          reasonTwo,
          reasonThree,
          chargebackAgain,
          againCode,
          remark,
          status
        } = this.info
        this.formData = {
          reason,
          reasonOne,
          reasonTwo,
          reasonThree,
          chargebackAgain: chargebackAgain || 1,
          againCode,
          remark,
          status
        }
      }
    }
  },
  methods: {
    // 获取退单原因
    async getSelectData () {
      const params = {
        code: 101436
      }
      const res = await dictThree(params)
      this.reasonList = res.data || []
    },
    // 获取再租订单
    async getLeaseAgainList () {
      const { customerId, rentStartDate } = this.info
      const params = {
        userId: customerId,
        beginStartDate: rentStartDate && rentStartDate.slice(0, 10)
      }
      const res = await orderList(params)
      // 拼接成需要的数据
      this.againCodeList = res.result.records.map(item => {
        const label = `${item.orderId} ${item.rentNumbers}台 （${formatDate(
          item.leaseBeginDate
        )}）`
        return {
          label,
          value: item.orderId
        }
      })
    },
    // 封装方法，删掉数组中的null的数据
    filterArrNull (arr) {
      return arr.filter(item => item !== null && item !== '0' && item !== '')
    },
    reasonChange (val) {
      switch (val.length) {
        case 0:
          this.formData.reason = ''
          this.formData.reasonOne = ''
          this.formData.reasonTwo = ''
          this.formData.reasonThree = ''
          break
        case 1:
          this.formData.reason = val[0]
          this.formData.reasonOne = ''
          this.formData.reasonTwo = ''
          this.formData.reasonThree = ''
          break
        case 2:
          this.formData.reason = val[0]
          this.formData.reasonOne = val[1]
          this.formData.reasonTwo = ''
          this.formData.reasonThree = ''
          break
        case 3:
          this.formData.reason = val[0]
          this.formData.reasonOne = val[1]
          this.formData.reasonTwo = val[2]
          this.formData.reasonThree = ''
          break
        case 4:
          this.formData.reason = val[0]
          this.formData.reasonOne = val[1]
          this.formData.reasonTwo = val[2]
          this.formData.reasonThree = val[3]
          break
      }
    },
    chargebackAgainChange (val) {
      this.formData.chargebackAgain = val
    },
    againCodeChange (val) {
      this.formData.againCode = val
    },
    remarkBlur (val) {
      this.formData.remark = val
    },
    statusChange (val) {
      this.formData.status = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const { id, orderCode } = this.info
          const params = {
            id,
            orderCode,
            ...this.formData
          }
          chargebackUpdate(params).then(_ => {
            this.$message.success('修改成功')
            this.close()
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .form_item_box {
    >div {
      width: 50%;
    }
  }
</style>
